import { PRIMARY } from '@root/src/theme/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    navigation: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        width: '100%',
        minHeight: '60px',
        zIndex: 1500,
        boxShadow: '0px -3px 3px #00000033',
        backgroundImage: 'linear-gradient(90deg, #173967 100%, #2E6BBF 0%)',
        // '& .active-indicator': {
        //     height: 5,
        //     width: 5,
        //     bottom: '2px',
        //     left: '14px',
        //     backgroundColor: 'white',
        //     borderRadius: '50%',
        //     position: 'absolute',
        // },
        '& .always-active-indicator': {
            height: 5,
            width: 5,
            backgroundColor: 'white',
            borderRadius: '50%',
            position: 'absolute',
            bottom: '2px',
            left: '14px',
        },
        '& .MuiBottomNavigationAction-label': {
            color: '#FFF',
            fontSize: '9px',
            lineHeight: '11px',
            paddingTop: '2px',
        },
        '& .MuiBottomNavigationAction-wrapper': {
            position: 'relative',
        },
        borderRadius: '4px 4px 0 0',
    },
    navAction: {
        color: 'white',
        paddingTop: '8px !important',
        paddingBottom: '8px !important',
        minWidth: 'unset',
    },
    navActionSelected: {
        color: 'white !important',
        position: 'relative',
        '& .active-indicator': {
            height: 5,
            width: 5,
            bottom: '2px',
            left: '11px',
            backgroundColor: 'white',
            borderRadius: '50%',
            position: 'absolute',
        },
    },
    middle: {
        marginTop: '10px',
        color: PRIMARY,
        backgroundColor: 'white',
        height: 78,
        width: 78,
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        '& svg': {
            height: 32,
        },
        '& .MuiBadge-anchorOriginTopRightRectangle': {
            top: '5px',
            right: '5px',
        },
        '& .MuiBadge-badge': {
            height: '21px',
            width: '21px',
        },
    },
}));

export default useStyles;
