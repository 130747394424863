/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
import { withApollo } from '@lib_apollo';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
// import HomeIcon from '@material-ui/icons/Home';
// import PersonIcon from '@material-ui/icons/Person';
// import WidgetsIcon from '@material-ui/icons/Widgets';
// import HeadsetMicIcon from '@material-ui/icons/HeadsetMic';
import Router from 'next/router';
import { useState } from 'react';
import dynamic from 'next/dynamic';
import useStyles from './style';

const BrowseModal = dynamic(() => import('@common_categorymodal'), { ssr: false });
const ShoppingBagIcon = dynamic(() => import('@modules/cart/plugins/ShoppingBag'), { ssr: false });

const Navigation = ({ active }) => {
    const styles = useStyles();
    const [openModal, setOpenModal] = useState(false);
    const handleOpenModal = (val) => {
        setOpenModal(val);
    };
    if (active) {
        return (
            <>
                <BottomNavigation
                    className={styles.navigation}
                    value={active}
                    showLabels
                    onChange={(event, newValue) => {
                        switch (newValue) {
                        case 'home':
                            handleOpenModal(false);
                            Router.push('/');
                            return;
                        case 'browse':
                            handleOpenModal(true);
                            return;
                        case 'cart':
                            handleOpenModal(false);
                            Router.push('/checkout/cart');
                            return;
                        case 'account':
                            handleOpenModal(false);
                            Router.push('/customer/account');
                            return;
                        case 'chat':
                            if (typeof window !== 'undefined') {
                                window.YellowMessengerPlugin.toggleChat();
                            }
                            break;
                        default:
                        }
                    }}
                >
                    {[
                        {
                            label: 'Home',
                            value: 'home',
                            icon: <><img alt="home" src="/assets/img/pdp/home.svg" /><div className={openModal ? '' : 'active-indicator'} /></>,
                        },
                        {
                            label: 'Search',
                            value: 'browse',
                            icon: <><img alt="menu" src="/assets/img/pdp/burger.svg" /><div className={openModal ? 'always-active-indicator' : ''} /></>,
                        },
                        {
                            label: 'Cart',
                            value: 'cart',
                            icon: <div className={styles.middle}><ShoppingBagIcon /><div className={openModal ? '' : 'active-indicator'} /> </div>,
                        },
                        {
                            label: 'Account',
                            value: 'account',
                            icon: <><img alt="account" src="/assets/img/pdp/account.svg" /><div className={openModal ? '' : 'active-indicator'} /></>,
                        },
                        {
                            label: 'Chat',
                            value: 'chat',
                            icon: <><img alt="cs" src="/assets/img/pdp/headphone.svg" /><div className="active-indicator" /></>,
                        },
                    ].map((el, i) => (
                        <BottomNavigationAction
                            key={i}
                            label={el.label}
                            value={el.value}
                            icon={el.icon}
                            classes={{
                                selected: styles.navActionSelected,
                                root: styles.navAction,
                            }}
                        />
                    ))}
                </BottomNavigation>
                <BrowseModal open={openModal} setOpenModal={handleOpenModal} />
            </>
        );
    }
    return null;
};

export default withApollo({ ssr: false })(Navigation);
